const elements = [
  'fv-box',
  'fv-abo-box',
  'fv-brand',
  'fv-bubble',
  'fv-button',
  'fv-hamburger',
  'fv-header',
  'fv-headline',
  'fv-icon',
  'fv-img',
  'fv-input',
  'fv-interstitial',
  'fv-megamenu',
  'fv-modal',
  'fv-motiv',
  'fv-overlay',
  'fv-price',
  'fv-reminder',
  'fv-section',
  'fv-select-inline',
  'fv-sidebar',
  'fv-slider',
  'fv-stoerer',
  'fv-tablist',
  'fv-teaser',
  'fv-topbar',
  'fv-underline',
  'fv-dropdown-button',
];

// Register all elements defined in above array
elements.forEach((item) => {
  if (document.getElementsByTagName(item).length > 0) {
    import(/* webpackChunkName: "[request]" */ `./Components/${item}/${item}`);
  }
});
